(function() {
    'use strict';

    angular.module('ionicLoginRedirect', [
        'ionicAlert',
        'ionicFeature',
        'ionicLogin',
        'uabDefaultVariable',
        'uabLocalStorage',
        'uabRedirect',
        'ui.router'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicLoginRedirect').controller(
        'LoginRedirectController',
        LoginRedirectController
    );
    
    LoginRedirectController.$inject = [
        'DefaultVariableService',
        'LoginRedirectService',
        'LoginService'
    ];
    
    function LoginRedirectController(
        DefaultVariableService,
        LoginRedirectService,
        LoginService
    ) {
        var LoginRedirectController = this;

        LoginRedirectController.redirect = redirect;
        function redirect(data) {
            LoginRedirectService.redirect(data);
        }

        LoginRedirectController.init = init;
        function init() {
            LoginService.register(
                LoginRedirectController,
                {
                    init:     false,
                    onLogin:  'redirect',
                    onLogout: 'redirect'
                }
            );
        }

        LoginRedirectController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicLoginRedirect').directive('ionicLoginRedirect', ionicLoginRedirect);

    function ionicLoginRedirect() {
        return {
            controller:   'LoginRedirectController',
            controllerAs: 'loginRedirectCtrl',
            restrict:     'E'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicLoginRedirect').service('LoginRedirectService', LoginRedirectService);

    LoginRedirectService.$inject = [
        'DefaultVariableService',
        'ENVIRONMENT',
        'FeatureListService',
        'LocalStorageService',
        'LoginService',
        'RedirectService',
        '$state'
    ];

    function LoginRedirectService(
        DefaultVariableService,
        ENVIRONMENT,
        FeatureListService,
        LocalStorageService,
        LoginService,
        RedirectService,
        $state
    ) {
        var LoginRedirectService = this;

        LoginRedirectService.go = go;
        function go(state) {
            $state.go(state);
        }

        LoginRedirectService.redirect = redirect;
        function redirect(data) {
            data = DefaultVariableService.get(
                data,
                $state.params
            );

            var state = DefaultVariableService.getString(
                data.sref,
                $state.current.name
            );

            var loginSref = DefaultVariableService.get(
                ENVIRONMENT.redirect.loginSref,
                'app.login'
            );

            var open = DefaultVariableService.getArray(
                ENVIRONMENT.redirect.open
            );

            if (LoginService.isLoggedIn()) {
                if (LocalStorageService.exists('afterLogin')) {
                    var afterLogin = LocalStorageService.get('afterLogin');

                    LocalStorageService.remove('afterLogin');

                    LoginRedirectService.go(afterLogin);
                }
                if (state === loginSref || state === 'login' || state === 'home' || state === '') {
                    return FeatureListService.getFeatures().then(
                        function(features) {
                            if (features) {
                                features = DefaultVariableService.getArray(features);

                                var state = 'app.home';
                                var featuresLength = features.length;
                                for (var i = 0; i < featuresLength; i++) {
                                    var feature = features[i];

                                    var featuresUserTypes = DefaultVariableService.getArray(
                                        feature.features_user_types
                                    );

                                    if (featuresUserTypes.length !== 0) {
                                        state = 'app.' + feature.key;
                                        break;
                                    }
                                }

                                LoginRedirectService.go(state);
                            }

                            return features;
                        }
                    );
                }
            } else {
                var openLength = open.length;
                for (var i = 0; i < openLength; i++) {
                    if (open[i] === state) {
                        return;
                    }
                }

                if (state !== loginSref && state !== 'login') {
                    LocalStorageService.add('afterLogin', state);

                    LoginRedirectService.go(loginSref);
                }
            }
        }

        LoginRedirectService.reset = reset;
        function reset() {

        }

        LoginRedirectService.init = init;
        function init() {
            LoginRedirectService.reset();

            RedirectService.registerBeforeInternalRedirect(
                LoginRedirectService.redirect
            );
        }

        LoginRedirectService.init();

        return LoginRedirectService;
    }
})();
